body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Sudoku {
  text-align: center;
  margin-top: 40px;
  font-family: 'Nunito', sans-serif;
}

.Board {
  border-collapse: collapse;
  margin: auto;
  background: whitesmoke;
  box-shadow: 0 0 15px -5px #0008;
  border-radius: 15px;
  overflow: hidden;
}

.Square.thickTop {
  border-top: 4px solid #3f51b5;
}

.Square.thickLeft {
  border-left: 4px solid #3f51b5;
}

.Square.conflict input {
  background: darkred;
}

.Square.conflict.editable {
  background: red;
}

td {
  margin: 0;
  padding:0;
}

.Square {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  padding: 0;
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 28px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5865f9;
  background: white;
  cursor: pointer;
  border-left: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
}

.hiddenNumbers .Square {
  font-size: 0;
  color: transparent;
}

.hiddenNumbers .Square.disabled {
  background-color: #ebebeb;
}

td:first-child .Square {
  border-left: none;
}

tr:first-child .Square {
  border-top: none;
}

.Sudoku:not(.readonly) .Square.related {
  background: #e6e6e6;
}
.Sudoku:not(.readonly) .Square.disabled.related {
  background-color: #dadada;
}
.Sudoku:not(.readonly) .Square.same-value {
  background-color: #b4c9ff;
}
.Sudoku:not(.readonly) .Square.disabled.same-value {
  background-color: #b4c9ff;
  color: #1f1f1f;
}

body {
  background: #f0f0f0;
}

.Sudoku:not(.readonly) .Square:hover {
  transition: background-color .3s ease-out;
  background-color: #d2d2d2;
}

.Sudoku:not(.readonly) .Square.selected .note {
  color: whitesmoke;
}

.readonly .Square {
  pointer-events: none;
  cursor: default;
}

.Sudoku:not(.readonly) .Square.selected {
  background-color: #86aaf0;
  color: white;
}

.Sudoku:not(.readonly) .Square.selected:hover {
  background-color: #86aaf0;
}

.Square.small {
  height: 40px;
  width: 40px;
}

.Square.disabled {
  background-color: #f5f5f5;
  color: #1f1f1f;
}

.sidebar {
  width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-subitem-text {
  font-size: 0.8rem;
}

.MuiList-root a {
  color: #161616;
  text-decoration: none;
}

.ChatMessage {
  padding: 10px;
  margin: 10px 0;
  width: 80%;
  border-radius: 5px;
  color: white;
}

.ChatMessage.sent{
  margin-left: auto;
  background-color: #34a1ea;
  border-bottom-right-radius: 0;
}

.ChatMessage.received {
  margin-right: auto;
  background-color: #55bc66;
  border-bottom-left-radius: 0;
}

.ChatMessage.system {
  margin: auto;
  color: darkslategrey;
  background-color: whitesmoke;
}
